/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@5.1.3/dist/css/bootstrap.min.css
 * - /npm/animate.css@4.1.1/animate.min.css
 * - /npm/phosphor-icons@1.4.2/src/css/icons.min.css
 * - /npm/sweetalert2@11.4.4/dist/sweetalert2.min.css
 * - /npm/izitoast@1.4.0/dist/css/iziToast.min.css
 * - /npm/izimodal-1.6.0@1.6.1/css/iziModal.min.css
 * - /npm/bootstrap-timepicker@0.5.2/css/bootstrap-timepicker.min.css
 * - /npm/select2@4.1.0-rc.0/dist/css/select2.min.css
 * - /npm/swiper@8.0.6/swiper-bundle.min.css
 * - /npm/datatables.net-bs5@1.10.25/css/dataTables.bootstrap5.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
